.blogshero{
    width: 100%;
    height: 100vh;
    background-color: var(--journeyColor);
    overflow: hidden;
}

.blogshero-content{
    position: absolute;
    margin: 17rem 15rem;
    width: 75%;
    padding: 5rem 5rem;
    background-color: var(--syndrome);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.syndrome-text h2{
    font-family: 'Poppins';
    color: var(--textColor);
}

.syn-text{
    margin-top: 3rem;
}

.syn-text p{
    font-size: 1.2rem;
    line-height: 2rem;
}

.syn-author{
    margin-top: 3rem;
}

.syn-author>:nth-child(1){
    color: var(--primaryColor);
}

.syn-author>:nth-child(2){
    font-weight: 400;
    font-size: 1rem;
}

.blogshero-right img{
    max-width: 40rem;
    height: 23rem;
}

.bc-social{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.bc-social a{
    color: var(--textColor);
}

.bc-social a:hover{
    color: var(--primaryColor);
}

.bbc-social-content{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 1rem;
    margin-left: 5%;
    margin-top: 15%;
}

.bbc-social-divider{
    border: 1px solid var(--textColor);
    height: 1rem;
}

.bbc-social-text{
    writing-mode: vertical-lr;
    rotate: 180deg;
    color: var(--textColor);
    cursor: pointer;
}

.bc-social-text:hover{
    color: var(--primaryColor);
}

/* media queries */

@media screen and (max-width: 1400px) {
    .blogshero-content{
        margin: 10rem 11rem;
        width: 80%;
    }

    .syn-text{
        width: 100%;
    }

    .syndrome-text h2{
        font-size: 3rem;
    }

    .syn-text p{
        font-size: 1rem;
    }

    .blogshero-right{
        width: 50%;
    }

    .blogshero-right img{
        max-width: 30rem;
        height: 20rem;
        margin-left: 2rem;
    }
}