.hero{
    width: 100%;
    height: 100vh;
    background: url('../../assets/author.png') no-repeat center/cover;
}

.hero-content{
    margin: auto;
    flex-direction: column;
    display: flex;
    width: 100%;
}

.hero-right{
    padding: 15rem 17rem;
}

.hero-right h1{
    letter-spacing: 1px;
    color: var(--primaryColor);
    font-family: 'Poppins';
}

.hero-divider{
    margin: 3rem 0;
    width: 12%;
    border: 1px solid var(--primaryColor);
}

.hero-right p{
    color: var(--whiteColor);
}

.hero-btn{
    margin: 17%;
}

.hero-btn button{
    display: block;
    margin: auto;
    color: var(--whiteColor);
    font-size: 1.5rem;
}

.hero-left{
    position: absolute;
    margin: 25% 6%;
    display: flex;
    flex-direction: column;
}


.hero-icons{
    flex-direction: column;
    writing-mode: vertical-lr;
}

.hero-icons a{
    color: var(--whiteColor);
    padding: 10px;
}

.social-divider{
    border: 1px solid var(--whiteColor);
    width: 1px;
    height: 20px;
    margin: 1rem .7rem;
}


.social-text h4{
    color: var(--whiteColor);
    cursor: pointer;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

.hero-icons a:hover{
    color: var(--primaryColor);
}

.social-text h4:hover{
    color: var(--primaryColor);
}

/* media queries */

@media screen and (max-width: 1400px) {
    .hero-right h1{
        font-size: 3.7rem;
    }

    .hero-right p{
        font-size: 1.3rem;
    }

    .hero-btn button{
        font-size: 1rem;
        width: 10rem;
    }
}

/* tablet */

@media screen and (max-width: 856px) {

    .hero{
        max-height: 60vh;
        overflow: hidden;
    }

    .hero-right{
        padding: 6rem 8rem;
    }

    .hero-right h1{
        text-align: justify;
        font-size: 2.5rem;
    }

    .hero-right p{
        font-size: 1rem;
    }

    .hero-divider{
        margin: 1.5rem 0;
    }

    .hero-left{
        margin: 11.5% 6%;
    }
}