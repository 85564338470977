.navbar{
    max-width: 100%;
    background: transparent;
}

.nav-links{
    display: flex;
    justify-content: flex-end;
    padding: 2rem 3rem;
    width: 100%;
    list-style-type: none;
    align-items: center;
    font-weight: 400;
    position: absolute;
    height: 10vh;
}

.nav-links li{
    padding: 0 20px;
    margin: 0 10px;
    color: var(--whiteColor);
}

.nav-links li:hover{
    color: var(--primaryColor);
}

.nav-links li::before{
    content: '';
    width: 0;
    height: 2px;
    background-color: var(--primaryColor);
    margin: auto;
    display: block;
}

.nav-links li:hover::before{
    width: 70%;
    transition: width 0.3s linear;
    margin-top: 5px;
}

.nav-links a{
    cursor: pointer;
}

@media screen and (max-width: 1400px) {
    .nav-links{
        padding: 2rem 2rem;
    }

    .nav-links li{
        padding: 10px;
        font-size: .9rem;
    }
}

