.certification{
    width: 100%;
    height: 110vh;
}

.certification-content{
    padding: 10rem 17rem;
    display: flex;
    justify-content: space-between;
}

.c-left{
    width: 50%;
    color: var(--textColor);
    font-family: 'Poppins';
}

.c-left-content{
    display: flex;
    align-items: center;
    gap: 1.1rem;
}

.c-divider{
    width: 1px;
    border: 1px solid var(--primaryColor);
    height: 2.5rem;
}

.c-permanent{
    margin-top: 10rem;
}

.c-permanent span{
    color: var(--primaryColor);
    font-style: italic;
}

/* right side */

.c.right{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.fakeeh{
    float: right;
}

.c-right-content{
    position: relative;
    margin-top: 10rem;
}

.c-right-content img{
    max-width: 30rem;
    float: right;
}

.c-right-content>:nth-child(1){
    position: absolute;
    width: 30rem;
    height: 21.5rem;
    border: 1px solid var(--primaryColor);
    z-index: -1;
    margin-left: 5rem;
    margin-top: -2rem;
}

.fakeeh{
    width: 100%;
    text-transform: capitalize;
}

.fakeeh h5{
    color: var(--textColor);
    font-size: 1.1rem;
    text-align: center;
    margin-top: 2rem;
}

.c-btn button{
    display: block;
    margin: auto;
    color: var(--textColor);
    font-size: 1.1rem;
    width: 12rem;
}

.c-btn button:hover{
    color: var(--whiteColor);
}

@media screen and (max-width: 1400px) {

    .certification{
        height: 120vh;
    }
    .certification-content{
        padding: 7rem 10rem;
    }

    .c-permanent h2{
        font-size: 3rem;
    }

    .c-btn button{
        font-size: 1rem;
        width: 10rem;
    }
}

@media screen and (max-width: 856px) {

    .certification{
        height: 95vh;
    }
    .certification-content{
        padding: 5rem 3.5rem;
    }

    .c-permanent h2{
        font-size: 2rem;
    }

    .c-right-content img{
        max-width: 20rem;
        margin-right: 5rem;

    }
    
    .c-right-content>:nth-child(1){
        width: 20rem;
        height: 14.5rem;
        margin-top: -1rem;
    }

    .fakeeh h5{
        font-size: 1rem;
    }

    .c-btn button{
        font-size: .8rem;
        width: 8rem;
    }
}