.rc-certification{
    width: 100%;
    height: 90vh;
    background-color: var(--journeyColor);
    overflow: hidden;
}

.rc-certification-content{
    padding-top: 15rem;
    padding-left: 17rem;
    display: flex;
    justify-content: space-between;
}

.rc-left{
    max-width: 50%;
    color: var(--textColor);
    font-family: 'Poppins';
}

.rc-left-content{
    display: flex;
    align-items: center;
    gap: 1.1rem;
}

.rc-divider{
    width: 1px;
    border: 1px solid var(--primaryColor);
    height: 2.5rem;
}

.rc-permanent{
    margin-top: 5rem;
    color: black;
    max-width: 85%;
}

.rc-permanent p{
    font-weight: 400;
    line-height: 2rem;
    font-size: 1.3rem;
}

.rc-author{
    margin-top: 5rem;
}

.rc-author>:nth-child(1){
    color: var(--primaryColor);
}

.rc-author>:nth-child(2){
    font-weight: 400;
    color: black;
}

/* right side */

.rc.right{
    max-width: 50%;
    display: flex;
    flex-direction: column;
}

.rc-arrows img{
    max-width: 3rem;
    cursor: pointer;
}

.rc-arrows>:nth-child(2){
    margin-left: 2rem;
}


.rc-right-content{
    background-color: var(--syndrome);
    max-width: 50rem;
    height: 25rem;
    margin-top: 2%;
    padding: 2.5rem;
}

.rc-divider1{
    border: 2px solid var(--primaryColor);
    max-width: 50rem;
    margin-top: 2rem;
}

.rc-certificates{
    display: flex;
    width: 100%;
}


.rc-certificates img{
    max-width: 50%;
    height: 20rem;
    margin-right: 5rem;
}

.rc-certificates>:nth-child(1){
    max-width: 50rem;
    height: 20rem;
    color: red;
    z-index: 1;
    position: absolute;
}

.rc-social{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.rc-social a{
    color: var(--textColor);
}

.rc-social a:hover{
    color: var(--primaryColor);
}

.rc-social-content{
    position: absolute;
    margin-left: -10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 1rem;
}

.rc-social-divider{
    border: 1px solid var(--textColor);
    height: 1rem;
}

.rc-social-text{
    writing-mode: vertical-lr;
    rotate: 180deg;
    color: var(--textColor);
    cursor: pointer;
}

.rc-social-text:hover{
    color: var(--primaryColor);
}

/* media queries */

@media screen and (max-width: 1400px) {

    .rc-certification{
        height: 100vh;
    }

    .rc-certification-content{
        padding-top: 15rem;
        padding-left: 10rem;
    }

    .rc-social-content{
        margin-left: -6rem;
    }

    .rc-permanent{
        width: 70%;
    }

    .rc-permanent p{
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .rc-right-content{
        width: 40rem;
        right: 0;
        position: absolute;
        height: 20rem;
    }

    .rc-divider1{
        width: 40rem;
        margin-top: 25rem;
    }

    .rc-certificates img{
        max-width: 40%;
        height: 15rem;
    }
}





