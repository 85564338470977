.moreblogs{
    width: 100%;
    height: 115vh;
    background-color: var(--journeyColor);
    overflow: hidden;
}

.moreblogs-content{
    display: flex;
    margin: 0 17rem;
    justify-content: space-evenly;
}

.t-card{
    margin-top: 15rem;
}

.blogs-btn{
    margin-top: 5%;
}
.blogs-btn button{
    display: block;
    margin: auto;
    font-size: 1.1rem;
    width: 12rem;
}

.moreblogs-text{
    margin-top: 5rem;
    position: absolute;
    left: 10rem;
    display: flex;
    align-items: center;
    gap: 1.1rem;
}

.moreblogs-text h2{
    color: var(--textColor);
    font-family: 'Poppins';
}

.moreblogs-div{
    border: 1px solid var(--primaryColor);
    height: 2.5rem;
}

@media screen and (max-width: 1400px) {

    .moreblogs{
        height: 130vh;
    }

    .moreblogs-content{
        margin: 0 10rem;
    }

    .moreblogs-content{
        margin: 0 10rem;
        gap: 2rem;
    }

    .blogs-btn button{
        font-size: 1rem;
        width: 10rem;
    }
}